import { Grid } from '@mui/material';
import { useTranslations } from 'next-intl';
import Head from 'next/head';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { FeatureFlags } from '@/common/enums';
import BrowserLanguageAlert from '@/components/BrowserLanguageAlert';
import Header from '@/components/Header';

export interface IPrimaryLayout extends React.ComponentPropsWithoutRef<'div'> {
  justify?: 'items-center' | 'items-start';
}

const PrimaryLayout: React.FC<IPrimaryLayout> = ({ children }) => {
  const tg = useTranslations('Generic');

  const isLanguageBarEnabled = useFeatureFlagEnabled(FeatureFlags.browserLanguageBar);

  if (Object.keys(tg('ok')).length == 0) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Esgrid</title>
      </Head>
      <Header />
      <Grid container className="page-container" justifyContent="center">
        <Grid item xs={12} xxl={10}>
          {isLanguageBarEnabled && <BrowserLanguageAlert />}
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default PrimaryLayout;
