export const detectBrowser = (): 'chrome' | 'ff' | 'safari' | 'edge' | null => {
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf('Firefox') > -1) {
    return 'ff';
  } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
    return 'safari';
  } else if (userAgent.indexOf('Edg') > -1) {
    return 'edge';
  } else if (userAgent.indexOf('Chrome') > -1) {
    return 'chrome';
  } else {
    return null;
  }
};
