export enum MessageTypeEnum {
  FIRST_LOGIN = 'firstLogin',
  FIRST_ASSESSMENT = 'firstAssessment',
}

export enum SignUpSteps {
  CompanyInfo,
  Info,
  Finish,
}

export enum FeatureFlags {
  hideInviteLinkIfAssReqCompleted = 'eng-367-hide-invite-if-completed',
  showSummaryAndDetailsExport = 'eng-482-summary-and-details-export',
  insightsCopilot = 'insights-copilot',
  showLithuanian = 'eng-537-lithuanian-language',
  sustainabilityReport = 'sustainability-report',
  rebrand = 'eng-589-product-rebrand',
  detailedExport = 'eng-645-detailed-export',
  browserLanguageBar = 'eng-644-browser-language-bar',
}

export enum ChatRole {
  USER,
  ASSISTANT,
}

export enum AssessmentInsightsArea {
  GENERAL = 'general',
  ENVIRONMENTAL = 'environmental',
  SOCIAL = 'social',
  GOVERNANCE = 'governance',
  OTHER = 'other',
}

export enum NaceCode {
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B = 'B',
  B5 = 'B5',
  B6 = 'B6',
  B7 = 'B7',
  B8 = 'B8',
  B9 = 'B9',
  C = 'C',
  C10 = 'C10',
  C11 = 'C11',
  C12 = 'C12',
  C13 = 'C13',
  C14 = 'C14',
  C15 = 'C15',
  C16 = 'C16',
  C17 = 'C17',
  C18 = 'C18',
  C19 = 'C19',
  C20 = 'C20',
  C21 = 'C21',
  C22 = 'C22',
  C23 = 'C23',
  C24 = 'C24',
  C25 = 'C25',
  C26 = 'C26',
  C27 = 'C27',
  C28 = 'C28',
  C29 = 'C29',
  C30 = 'C30',
  C31 = 'C31',
  C32 = 'C32',
  C33 = 'C33',
  D = 'D',
  D35 = 'D35',
  E = 'E',
  E36 = 'E36',
  E37 = 'E37',
  E38 = 'E38',
  E39 = 'E39',
  F = 'F',
  F41 = 'F41',
  F42 = 'F42',
  F43 = 'F43',
  G = 'G',
  G45 = 'G45',
  G46 = 'G46',
  G47 = 'G47',
  H = 'H',
  H49 = 'H49',
  H50 = 'H50',
  H51 = 'H51',
  H52 = 'H52',
  H53 = 'H53',
  I = 'I',
  I55 = 'I55',
  I56 = 'I56',
  J = 'J',
  J58 = 'J58',
  J59 = 'J59',
  J60 = 'J60',
  J61 = 'J61',
  J62 = 'J62',
  J63 = 'J63',
  K = 'K',
  K64 = 'K64',
  K65 = 'K65',
  K66 = 'K66',
  L = 'L',
  L68 = 'L68',
  M = 'M',
  M69 = 'M69',
  M70 = 'M70',
  M71 = 'M71',
  M72 = 'M72',
  M73 = 'M73',
  M74 = 'M74',
  M75 = 'M75',
  N = 'N',
  N77 = 'N77',
  N78 = 'N78',
  N79 = 'N79',
  N80 = 'N80',
  N81 = 'N81',
  N82 = 'N82',
  O = 'O',
  O84 = 'O84',
  P = 'P',
  P85 = 'P85',
  Q = 'Q',
  Q86 = 'Q86',
  Q87 = 'Q87',
  Q88 = 'Q88',
  R = 'R',
  R90 = 'R90',
  R91 = 'R91',
  R92 = 'R92',
  R93 = 'R93',
  S = 'S',
  S94 = 'S94',
  S95 = 'S95',
  S96 = 'S96',
  T = 'T',
  T97 = 'T97',
  T98 = 'T98',
  U = 'U',
  U99 = 'U99',
}

export const NaceCodeSections = [
  NaceCode.A,
  NaceCode.B,
  NaceCode.C,
  NaceCode.D,
  NaceCode.E,
  NaceCode.F,
  NaceCode.G,
  NaceCode.H,
  NaceCode.I,
  NaceCode.J,
  NaceCode.K,
  NaceCode.L,
  NaceCode.M,
  NaceCode.N,
  NaceCode.O,
  NaceCode.P,
  NaceCode.Q,
  NaceCode.R,
  NaceCode.S,
  NaceCode.T,
  NaceCode.U,
];

export type NaceCodeType = `nace.${NaceCode}`;
