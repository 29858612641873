import { useEffect, useState } from 'react';

import { Close, Language } from '@mui/icons-material';
import { Alert, IconButton, Link, Typography } from '@mui/material';

import { LanguageAlertLanguageCode } from '@/types/LanguageAlertLanguageCode';
import { LanguageAlertTranslation } from '@/types/LanguageAlertTranslation';
import { detectBrowser } from '@/utils/detect-browser';

import translationsJson from '../../messages/languageAlert.json';

const getLanguageAlertTranslations = (): {
  title: string;
  actionLinkText: string;
  actionLink: string;
} | null => {
  const userLanguage = navigator.language.split('-')[0] || 'en';

  const browser = detectBrowser();

  const translations: LanguageAlertTranslation = translationsJson;

  const languageCode = userLanguage in translations ? userLanguage : 'en';
  const translation = translations[languageCode as LanguageAlertLanguageCode];

  if (browser && translation) {
    return {
      title: translation.title,
      actionLinkText: translation.actionLinkText,
      actionLink: translation.actionLink[browser],
    };
  }

  return null;
};

const BrowserLanguageAlert = () => {
  const translations = getLanguageAlertTranslations();

  const [isLanguageAlertOpen, setIsLanguageAlertOpen] = useState(false);

  useEffect(() => {
    const isAlertClosed = localStorage.getItem('languageAlertClosed');
    setIsLanguageAlertOpen(isAlertClosed !== 'true');
  }, []);

  const handleClose = () => {
    localStorage.setItem('languageAlertClosed', 'true');
    setIsLanguageAlertOpen(false);
  };

  return (
    translations &&
    isLanguageAlertOpen && (
      <Alert
        severity="info"
        icon={<Language />}
        sx={{
          '& .MuiAlert-message': {
            width: '100%',
          },
        }}
        action={
          <IconButton aria-label="close" color="inherit" onClick={handleClose} size="small">
            <Close />
          </IconButton>
        }
      >
        <div style={{ display: 'flex', lineHeight: '22px', justifyContent: 'space-between' }}>
          <Typography variant="body1" fontWeight="500">
            {translations.title}
          </Typography>
          <Link
            href={translations.actionLink}
            variant="body2"
            fontWeight="500"
            target="_blank"
            sx={{
              color: '#0288D1',
              textDecoration: 'none',
              lineHeight: '22px',
              marginRight: '10px',
              textTransform: 'uppercase',
            }}
          >
            {translations.actionLinkText}
          </Link>
        </div>
      </Alert>
    )
  );
};

export default BrowserLanguageAlert;
